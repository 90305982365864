<template>
  <div>
    <el-breadcrumb separator="/">
      <template v-for="(item, i) in breadList">
        <el-breadcrumb-item v-if="i === 0" :key="i" :to="{ path: item.path }">{{ item.meta.title }}</el-breadcrumb-item>
        <el-breadcrumb-item v-else :key="i" to="">{{ item.meta.title }}</el-breadcrumb-item>
      </template>
    </el-breadcrumb>
  </div>
</template>
<script>
export default {
  data() {
    return {
      breadList: [] // 路由集合
    }
  },
  watch: {
    $route() {
      this.getBreadcrumb()
    }
  },
  methods: {
    isHome(route) {
      console.log(route)
      return route.name === 'Home'
    },
    getBreadcrumb() {
      let matched = this.$route.matched
      let newMatch = []
      matched.map(item => {
        if (item.name !== 'Layout') {
          newMatch.push(item)
        }
      })
      console.log(newMatch)
      //如果不是首页
      if (!this.isHome(newMatch[0])) {
        newMatch = [{ path: '/home', meta: { title: '首页' } }].concat(newMatch)
      }
      this.breadList = newMatch
      console.log(this.breadList)
    }
  },
  created() {
    this.getBreadcrumb()
  }
}
</script>
<style scoped lang="less">
/deep/.el-breadcrumb {
  height: 40px;
  line-height: 40px;
  background: #fff;
  padding: 1%;
  border-bottom: 1px solid #eee;
}
</style>
