<template>
  <div>
    <div class="wrap">
      <el-aside class="aside">
        <div class="title">
          <img src="../assets/logo.png" class="logo" />Klinter官网管理系统
          <!-- <span>科伦健康战略</span>
               <span>发展研究中心</span> -->
        </div>
        <el-menu class="el-menu-vertical-demo" :router="true" :unique-opened="true" :default-openeds="menuArr">
          <el-submenu index="1">
            <template slot="title">
              <i class="el-icon-tickets"></i>
              <span>新闻中心</span>
            </template>
            <template>
              <el-menu-item index="newsList">新闻列表</el-menu-item>
            </template>
          </el-submenu>
          <el-submenu index="2">
            <template slot="title">
              <i class="el-icon-chat-line-square"></i>
              <span>留言管理</span>
            </template>
            <template>
              <el-menu-item index="messageList">留言列表</el-menu-item>
            </template>
          </el-submenu>
        </el-menu>
      </el-aside>
      <el-main>
        <div class="user-info">
          <el-dropdown>
            <span class="el-dropdown-link"> <img src="../assets/head.png" class="user-img" /> {{ username }}<i class="el-icon-arrow-down el-icon--right"></i> </span>
            <el-dropdown-menu slot="dropdown">
              <!-- <el-dropdown-item>帮助中心</el-dropdown-item> -->
              <el-dropdown-item divided @click.native="handleLogout">退出登录</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <Breadcrumb />
        <router-view />
      </el-main>
    </div>
    <!-- <div v-else><router-view /></div> -->
  </div>
</template>
<script>
// import {logout} from '../service/login'
import Breadcrumb from '@/components/Breadcrumb.vue'
export default {
  name: 'layout',
  data() {
    return {
      username: '',
      menuArr: []
    }
  },
  components: {
    Breadcrumb
  },
  mounted() {
    if (localStorage.getItem('username')) {
      this.username = localStorage.getItem('username')
    }
  },
  methods: {
    handleLogout() {
      localStorage.clear()
      this.$router.push('/login')
    }
  }
}
</script>
<style scoped lang="less">
/deep/.el-main {
  padding: 0;
}
.wrap {
  width: 100%;
}
.header {
  height: 60px !important;
  color: #fff;
  background: #3c8dbc;
  padding: 0 20px;
  border-bottom: 1px solid #fff;
  overflow: hidden;
}
.header .title {
  font-size: 22px;
}
.header-r {
  text-align: right;
  line-height: 60px;
  font-size: 14px;
}
.logout {
  cursor: pointer;
  margin-left: 20px;
}
.aside {
  width: 260px !important;
  background: #001529;
  height: 100%;
  color: #fff;
  position: fixed;
  left: 0;
  bottom: 0;
}
.login {
  margin-top: 30px;
  margin-bottom: 30px;
}
.login p {
  text-align: center;
  margin-top: 10px;
}
.face {
  width: 80px;
  height: 80px;
  margin: 0 auto;
  border-radius: 100%;
  overflow: hidden;
  border: 4px solid #44576b;
}
.face img {
  width: 100%;
  height: 100%;
}
.aside .title {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  text-align: center;
  line-height: 60px;
  background: #002140;
  cursor: pointer;
}
.aside .title img {
  width: 35px;
  height: 42px;
  margin-right: 5px;
}
.aside .title span {
  display: block;
  line-height: 28px;
}
.el-main {
  margin-left: 260px;
  background: #eee;
}
.user-info {
  position: relative;
  height: 50px;
  line-height: 50px;
  text-align: right;
  border-bottom: 1px solid #eee;
  background: #fff;
}
.user-img {
  width: 40px;
  height: 40px;
  margin-right: 2px;
}
/deep/.el-dropdown {
  position: absolute;
  right: 10px;
  top: 0;
  cursor: pointer;
}
/deep/.el-dropdown-link {
  display: flex;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  align-items: center;
}
.el-menu-item {
  width: 260px !important;
}

/deep/.el-menu {
  width: 260px !important;
  background: none;
  border: none;
  text-align: left;
}
.el-submenu {
  width: 260px !important;
  color: #fff;
}
/deep/.el-submenu__title {
  font-size: 14px;
  color: #fff;
  text-align: left;
}

.el-submenu__title i {
  color: #fff;
}
/deep/.el-menu-item:focus,
.el-menu-item:hover {
  outline: 0;
  background-color: #4e5465;
}
/deep/.el-submenu__title:focus,
.el-submenu__title:hover {
  outline: 0;
  background-color: #4e5465 !important;
}
/deep/.el-submenu__title:hover {
  background-color: #4e5465;
  padding-left: 0;
  border-left: 4px solid #2d8cf0;
}

/deep/.el-submenu .el-menu {
  background-color: rgba(0, 0, 0, 0.5);
}
.el-menu--inline {
  background: #ff0000;
}
.el-menu-item {
  color: #fff;
}
.el-menu-item:hover {
  outline: 0 !important;
  color: #fff !important;
  background: #4e5465;
}
.el-menu-item.is-active {
  color: #fff !important;
  background: #2d8cf0 !important;
}

/deep/.is-opened .el-submenu__title {
  padding-left: 10px !important;
  border-left: 4px solid #2d8cf0;
}
.el-menu-item:focus,
.el-menu-item:hover {
  outline: 0;
  background-color: #4e5465;
}

.el-submenu__title:focus,
.el-submenu__title:hover {
  outline: 0;
  background-color: #4e5465;
}

.el-submenu__title.is-disabled {
  opacity: 0.25;
  cursor: not-allowed;
  background: 0 0 !important;
}

.el-submenu__title:hover {
  background-color: #4e5465;
}
.el-menu-item i {
  color: #fff;
}
</style>
